
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import Sobre from '../views/SobreView.vue'
import Catalogos from '../views/CatalogosView.vue'
import Blog from '../views/BlogView.vue'
import FaleConosco from '../views/FaleConoscoView.vue'
import Informacoes from '../views/InformacoesView.vue'
import Produtos from '../views/ProdutosView.vue'
import ProdutosDetalhe from '../views/ProdutosDetalheView.vue'
import Carrinho from '../views/CarrinhoView.vue'
import Endereco from '../views/EnderecoView.vue'
import Login from '../views/LoginView.vue'
//import MensagemPedido from '../views/MensagemPedidoView.vue'
//import Pagamento from '../views/PagamentoView.vue'
//import PagamentoMercadoPago from '../views/PagamentoMercadoPagoView.vue'
import PedidoRealizado from '../views/PedidoRealizadoView.vue'
import PedidoNegado from '../views/PedidoNegadoView.vue'
import MeusPedidos from '../views/MeusPedidosView.vue'
import MeusPedidosDetalhe from '../views/MeusPedidosDetalheView.vue'
import MeusDados from '../views/MeusDadosView.vue'

import TreinamentosEventos from '../views/TreinamentosEventosView.vue'
import Videos from '../views/VideosView.vue'


const routes = [
 { path: '/', name: 'Home', component: Home },
 { path: '/Sobre', name: 'Sobre', component: Sobre },
 { path: '/Catalogos/:idSegmento?/:nomeSegmento?', name: 'Catalogos', component: Catalogos },
 { path: '/Blog/:idBlog?', name: 'Blog', component: Blog },
 { path: '/FaleConosco', name: 'FaleConosco', component: FaleConosco },
 { path: '/Informacoes/:idInformacoes?/:nomeInformacoes?', name: 'Informacoes', component: Informacoes },
 { path: '/Produtos/:idSegmentoProduto?/:nomeSegmento?', name: 'Produtos', component: Produtos},
 { path: '/ProdutosPesquisa/:chavepesquisa?', name: 'ProdutosPesquisa', component: Produtos},
 { path: '/ProdutosDetalhe/:idProduto/:nomeProduto', name: 'ProdutosDetalhe', component: ProdutosDetalhe},
 { path: '/Carrinho', name: 'Carrinho', component: Carrinho },
 { path: '/Endereco', name: 'Endereco', component: Endereco },
{ path: '/Login', name: 'Login', component: Login },
//{ path: '/MensagemPedido', name: 'MensagemPedido', component: MensagemPedido },
//{ path: '/Pagamento', name: 'Pagamento', component: Pagamento },
//{ path: '/PagamentoMercadoPago', name: 'PagamentoMercadoPago', component: PagamentoMercadoPago },
{ path: '/PedidoRealizado', name: 'PedidoRealizado', component: PedidoRealizado },
{ path: '/PedidoNegado', name: 'PedidoNegado', component: PedidoNegado },
{ path: '/MeusPedidos', name: 'MeusPedidos', component: MeusPedidos },
{ path: '/MeusPedidosDetalhe/:idPedido', name: 'MeusPedidosDetalhe', component: MeusPedidosDetalhe },
{ path: '/MeusDados', name: 'MeusDados', component: MeusDados },
{ path: '/Videos/:idSegmento?/:nomeSegmento?', name: 'Videos', component: Videos },
{ path: '/TreinamentosEventos/:idTreinamento?', name: 'TreinamentosEventos', component: TreinamentosEventos },
{ path: '/:catchAll(.*)', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  next()
})

export default router
