<template>
    <div>
        <header class="hidden-sm hidden-xs">
            <!-- Header Start -->
            <div class="header-area header-transparent">
                <div class="main-header ">
                    <div class="header-top d-none d-lg-block" style="background:rgb(68,62,64, 0.7);">
                        <div class="container-fluid">
                            <div class="col-xl-12">
                                <div class="row d-flex justify-content-between align-items-center" v-if="topo != ''">
                                    <div class="header-info-left">
                                        <ul>
                                            <li>{{ topo[0].itiNome }}</li>
                                            <li><a :href="topo[0].itiLinkBtn1" target="_blank" style="color:#ffffff;">{{
                                                topo[0].itiReferencia }} <img src="/images/whattsico.png" style="width: 20px;"></a></li>
                                                <li>
                                                    <div style="color:#ffffff;">{{ variavelHeader1 }}</div>
                                                </li>
                                        </ul>
                                    </div>
                                    
                                    <div class="header-info-right" v-if="redes!=''">
                                        <ul class="header-social">
                                            <li><a v-if="redes[0].itiReferencia != '' && redes[0].itiReferencia != null"
                                                    :href="redes[0].itiReferencia" target="_blank"><i
                                                        class="fab fa-instagram"></i></a></li>
                                            <li><a v-if="redes[0].itiNome != '' && redes[0].itiNome != null"
                                                    :href="redes[0].itiNome" target="_blank"><i
                                                        class="fab fa-facebook-f"></i></a></li>
                                            <li><a v-if="redes[0].itiCampoExtra2 != '' && redes[0].itiCampoExtra2 != null"
                                                    :href="redes[0].itiCampoExtra2" target="_blank"><i
                                                        class="fab fa-linkedin-in"></i></a></li>
                                            <li> <a v-if="redes[0].itiCampoExtra1 != '' && redes[0].itiCampoExtra1 != null"
                                                    :href="redes[0].itiCampoExtra1" target="_blank"><i
                                                        class="fab fa-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="header-bottom  header-sticky" style="background:#ffffff;">
                        <div class="container-fluid">
                            <div class="row align-items-center">
                                <!-- Logo -->
                                <div class="col-xl-2 col-lg-2 col-md-1">
                                    <div class="logo">
                                        <!-- logo-1 -->
                                        <router-link to="/" class="big-logo">
                                            <img src="/imagens/logo.png" alt="" style="width: 210px; height: auto;">
                                         </router-link>
                                    </div>
                                </div>
                                <div class="col-xl-8 col-lg-8 col-md-8">
                                    <!-- Main-menu -->
                                    <div class="main-menu f-right d-none d-lg-block">
                                        <nav>
                                            <ul id="navigation">
                                                <li><router-link to="/">Home</router-link></li>
                                                <li><router-link to="/sobre">Sobre</router-link></li>
                                                <li>
                                                    <router-link to="/produtos/0/Marcas">Produtos</router-link>
                                                    <ul class="submenu"> <!-- Submenu -->
                                                        <li v-for="seg in SegmentosMenu" :key="seg.psgId">
                                                            <router-link :to="'/produtos/' + seg.psgId + '/' + seg.psgNomeSegmento.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '')">
                                                                {{ seg.psgNomeSegmento }}
                                                            </router-link>
                                                        </li>
                                                    </ul>
                                                    </li>
                                                <li><router-link to="/blog">Blog</router-link></li>
                                                <li><router-link to="/faleconosco">Fale Conosco</router-link></li>
                                                <li>
                                                    <router-link to="/catalogos">Catálogos</router-link>
                                                    <ul class="submenu"> <!-- Submenu -->
                                                        <li v-for="seg in SegmentoCatalogo" :key="seg.sgiId">
                                                            <router-link :to="'/catalogos/' + seg.sgiId + '/' + seg.sgiNomeSegmento.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '')">
                                                                {{ seg.sgiNomeSegmento }}
                                                            </router-link>
                                                        </li>
                                                    </ul>
                                                    </li>
                                                <li>
                                                    <router-link to="/videos">Vídeos</router-link>
                                                    <ul class="submenu"> <!-- Submenu -->
                                                        <li v-for="seg in SegmentoVideo" :key="seg.sgiId">
                                                            <router-link :to="'/videos/' + seg.sgiId + '/' + seg.sgiNomeSegmento.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '')">
                                                                {{ seg.sgiNomeSegmento }}
                                                            </router-link>
                                                        </li>
                                                    </ul>
                                                    </li>
                                                <li>
                                                    <router-link v-if="variavelHeader1 == ''" to="/login">Login</router-link>
                                                    <a v-else href="#" @click="Logout()">Deslogar</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-3">
                                    <div class="header-right-btn f-right d-none d-lg-block">
                                        <router-link to="/carrinho" class="btn">Carrinho 
                                            <i class="fa fa-shopping-cart"></i> 
                                            <span v-if="variavelHeader2 != ''" style="position: relative;
                                                top: -15px;
                                                right: 9px;
                                                background-color: #d1c9c9;
                                                color: #000000;
                                                border-radius: 50%;
                                                padding-left: 6px;
                                                padding-right: 6px;
                                                padding-top: 3px;
                                                padding-bottom: 2px;
                                                font-size: 9px;">{{ variavelHeader2 }}</span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Header End -->
        </header>

        <div class="container hidden-lg hidden-md" style="padding-bottom: 60px;">
            <v-navigation-drawer v-model="drawer">
                <!-- Conteúdo do menu lateral -->
                <router-link to="/" class="scrollto"
                    style="color: #000;letter-spacing: 2px;padding-right: 0.3em;font-size: 25px;padding-top: 15px;">
                    <img src="/imagens/logo.png" style="width:150px;padding: 15px;">
                   
                </router-link>
                <v-list>
                    <v-list-item link to="/">
                        <v-list-item-title>Início</v-list-item-title>
                    </v-list-item>
                    <v-list-item link to="/sobre">
                        <v-list-item-title>sobre</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="drawer3 = !drawer3; drawer = !drawer;">
                        <v-list-item-title class="d-inline">Produtos</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item link to="/blog">
                        <v-list-item-title>Blog</v-list-item-title>
                    </v-list-item>
                    <v-list-item link to="/faleconosco">
                        <v-list-item-title>Fale Conosco</v-list-item-title>
                    </v-list-item>

                    <v-list-item link @click="drawer2 = !drawer2; drawer = !drawer;">
                        <v-list-item-title class="d-inline">Informações Gerais</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item link @click="drawer4 = !drawer4;drawer = !drawer;">
                        <v-list-item-title class="d-inline">Catálogos</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item link @click="drawer5 = !drawer5; drawer = !drawer;">
                        <v-list-item-title class="d-inline">Vídeos</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item link to="/Login">
                        <v-list-item-title>Login</v-list-item-title>
                    </v-list-item>
                    <v-list-item link to="/Carrinho">
                        <v-list-item-title>Carrinho</v-list-item-title>
                    </v-list-item>
                    <v-list-item link to="/MeusPedidos">
                        <v-list-item-title>Meus Pedidos</v-list-item-title>
                    </v-list-item>
                    <v-list-item link to="/MeusDados">
                        <v-list-item-title>Minha Conta</v-list-item-title>
                    </v-list-item>
                </v-list>
                <v-row class="justify-content-center" style="padding-top:30px;">
                    <div class="d-flex justify-content-center" v-if="redes != ''">
                        <a v-if="redes[0].itiNome != '' && redes[0].itiNome != null" :href="redes[0].itiNome"
                            target="_blank" title="Facebook" style="color:#000000;font-size:13px;padding-right:15px;"><i
                                class="icon-facebook-f"></i></a>
                        <a v-if="redes[0].itiReferencia != '' && redes[0].itiReferencia != null"
                            :href="redes[0].itiReferencia" target="_blank" title="Instagram"
                            style="color:#000000;font-size:13px;padding-right:15px;"><i class="icon-instagram"></i></a>
                        <a v-if="redes[0].itiCampoExtra1 != '' && redes[0].itiCampoExtra1 != null"
                            :href="redes[0].itiCampoExtra1" target="_blank" title="Youtube"
                            style="color:#000000;font-size:13px;padding-right:15px;"><i class="icon-youtube"></i></a>
                        <a v-if="redes[0].itiCampoExtra2 != '' && redes[0].itiCampoExtra2 != null"
                            :href="redes[0].itiCampoExtra2" target="_blank" title="Linkedin" style="color:#000000;"><i
                                class="icon-linkedin"></i></a>
                    </div><!-- End .social-icons -->

                </v-row>
            </v-navigation-drawer>


            <v-navigation-drawer v-model="drawer2">
                <!-- Conteúdo do menu lateral -->
                <router-link to="/" class="scrollto"
                    style="color: #000;letter-spacing: 2px;padding-right: 0.3em;font-size: 25px;padding-top: 15px;">
                    <img src="/imagens/logo.png" style="width:150px;padding: 15px;">
                </router-link>
                <v-list>
                    <v-list-item link @click="drawer2 = !drawer2; drawer = !drawer;">
                        <v-list-item-icon>
                            <v-icon class="d-inline">mdi-chevron-left</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="d-inline">Voltar</v-list-item-title>
                    </v-list-item>
                    <div v-for="itens in informacoes" :key="itens.itiId">
                        <v-list-item link
                            :to="'/informacoes/' + itens.itiId + '/' + itens.itiNome.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '')">
                            <v-list-item-title> {{ itens.itiNome }}</v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list>
            </v-navigation-drawer>

            <v-navigation-drawer v-model="drawer3">
                <!-- Conteúdo do menu lateral -->
                <router-link to="/" class="scrollto"
                    style="color: #000;letter-spacing: 2px;padding-right: 0.3em;font-size: 25px;padding-top: 15px;">
                    <img src="/imagens/logo.png" style="width:150px;padding: 15px;">
                </router-link>
                <v-list>
                    <v-list-item link @click="drawer3 = !drawer3; drawer = !drawer;">
                        <v-list-item-icon>
                            <v-icon class="d-inline">mdi-chevron-left</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="d-inline">Voltar</v-list-item-title>
                    </v-list-item>
                    <div v-for="seg in SegmentosMenu" :key="seg.psgId" class="d-inline">
                        <v-list-item link
                            :to="'/produtos/' + seg.psgId + '/' + seg.psgNomeSegmento.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '')">
                            <i class="fa fa-cog" style="color:#000000;"></i>
                            <v-list-item-title
                                style="font-weight: 500; font-size: 14px;padding-left: 8px;color:#333333;display: inline-flex;">
                                {{ seg.psgNomeSegmento }}</v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list>
            </v-navigation-drawer>

            <v-navigation-drawer v-model="drawer4">
                <!-- Conteúdo do menu lateral -->
                <router-link to="/" class="scrollto"
                    style="color: #000;letter-spacing: 2px;padding-right: 0.3em;font-size: 25px;padding-top: 15px;">
                    <img src="/imagens/logo.png" style="width:150px;padding: 15px;">
                </router-link>
                <v-list>
                    <v-list-item link @click="drawer4 = !drawer4; drawer = !drawer;">
                        <v-list-item-icon>
                            <v-icon class="d-inline">mdi-chevron-left</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="d-inline">Voltar</v-list-item-title>
                    </v-list-item>
                    <div v-for="seg in SegmentoCatalogo" :key="seg.psgId" class="d-inline">
                        <v-list-item link
                            :to="'/catalogos/' + seg.sgiId + '/' + seg.sgiNomeSegmento.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '')">
                            <i class="fa fa-cog" style="color:#000000;"></i>
                            <v-list-item-title
                                style="font-weight: 500; font-size: 14px;padding-left: 8px;color:#333333;display: inline-flex;">
                                {{ seg.sgiNomeSegmento }}</v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list>
            </v-navigation-drawer>

            <v-navigation-drawer v-model="drawer5">
                <!-- Conteúdo do menu lateral -->
                <router-link to="/" class="scrollto"
                    style="color: #000;letter-spacing: 2px;padding-right: 0.3em;font-size: 25px;padding-top: 15px;">
                    <img src="/imagens/logo.png" style="width:150px;padding: 15px;">
                </router-link>
                <v-list>
                    <v-list-item link @click="drawer5 = !drawer5; drawer = !drawer;">
                        <v-list-item-icon>
                            <v-icon class="d-inline">mdi-chevron-left</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="d-inline">Voltar</v-list-item-title>
                    </v-list-item>
                    <div v-for="seg in SegmentoVideo" :key="seg.psgId" class="d-inline">
                        <v-list-item link
                            :to="'/videos/' + seg.sgiId + '/' + seg.sgiNomeSegmento.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '')">
                            <i class="fa fa-cog" style="color:#000000;"></i>
                            <v-list-item-title
                                style="font-weight: 500; font-size: 14px;padding-left: 8px;color:#333333;display: inline-flex;">
                                {{ seg.sgiNomeSegmento }}</v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list>
            </v-navigation-drawer>

            <!-- Conteúdo principal -->
            <v-app-bar app>
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                <v-row align="center" class="logo-cart-container">
                    <v-col class="logo-col">
                        <img src="/imagens/logo.png" class="logo-img" style="width:130px;">
                    </v-col>
                    <v-col class="icon-col">
                        <div class="dropdown cart-dropdown">
                            <router-link to="/carrinho" title="Carrinho">
                                <i class="fa fa-shopping-cart" style="color:#000000;"></i>
                            </router-link>
                        </div><!-- End .cart-dropdown -->
                    </v-col>
                    <v-col class="icon-col" style="padding-right: 20px;">
                        <div class="dropdown cart-dropdown">
                            <div class="icon" style="padding-top: 6px;padding-right: 10px;"
                                @click="showSearch = !showSearch">
                                <i class="fa fa-search" style="font-size: 20px;"></i>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-app-bar>
            <v-row v-if="showSearch" class="search-container">
            <v-col cols="10"> <!-- Usei cols="10" para que o campo de entrada ocupe 10 colunas -->
                <div class="header-search-wrapper">
                    <input type="search" class="form-control" v-model="pesquisaChave" @keyup.enter="Pesquisa()"
                        placeholder="Pesquise..." required>
                </div>
            </v-col>
            <v-col cols="2" class="d-flex align-center justify-center"> <!-- Usei cols="2" para que o ícone ocupe 2 colunas -->
                <a href="#" class="" @click="Pesquisa()"><i class="fa fa-search"
                        style="font-size: 20px;"></i></a>
                <!-- Ajuste a classe 'align-center' e 'justify-center' conforme necessário -->
            </v-col>
        </v-row>

        </div>

    </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import { mapState } from 'vuex';

export default {
    name: 'PageHeader',
    components: {

    },
    data: () => ({
        topo: [],
        redes: [],
        pesquisaChave: '',
        informacoes: [],
        drawer: false,
        drawer2: false,
        drawer3: false,
        drawer4: false,
        drawer5: false,
        showSearch: false,
        Segmentos: [],
        SegmentosMenu: [],
        SegmentosInst: [],
        SegmentosMenuInst: [],
        SegmentoVideo:[],
        SegmentoCatalogo:[],
    }),
    methods: {
        calculatotal() {
            var valorAvulsos = 0;
            var valorProdutos = 0;
            var vcount = 0;
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);

                if (dadosArmazenados.avulsos != '') {

                    dadosArmazenados.avulsos.forEach(item => {
                        //valorAvulsos += item.praValorPor;
                        vcount++;
                    });
                }

                if (dadosArmazenados.produtos != '') {

                    dadosArmazenados.produtos.forEach(item => {
                        //valorProdutos += item.proProdutosPrecos[0].ppcValorPor;
                        vcount++;
                    });
                }
            }
            this.valorTotal = valorProdutos + valorAvulsos;
            this.$store.commit('updateVariavelHeader2', vcount);
        },
        Pesquisa() {
            if (this.pesquisaChave == "") {
                this.$mensagemAviso("Digite uma palavra chave para pesquisa.")
            }
            else {
                const novaURL = `/CestasporCategoriaPesquisa/${this.pesquisaChave}`;
                window.history.pushState({}, '', novaURL);
            }
        },
        Logout() {
        Cookies.remove(this.$CookieCliente);
        this.$store.commit('updateVariavelHeader1', '');
        this.$router.push('/')
        
        },
    },
    async created() {

        const cookieData = Cookies.get(this.$CookieCliente);
        if (cookieData) {
            const _dadosRecuperados = JSON.parse(cookieData);
            this.$store.commit('updateVariavelHeader1', "Olá, " + _dadosRecuperados.nomeUsuario);
        }
        this.calculatotal();

        axios.get('/Process/InsInstitucional/institucional/9/cabecalho')
            .then(response => {
                this.topo = response.data;
                console.log('this.topo')
                console.log(this.topo)
            })
            .catch(error => {
                console.error(error);
            });

        axios.get('/Process/InsInstitucional/institucional/20/redessociais')
            .then(response => {
                this.redes = response.data;
            })
            .catch(error => {
                console.error(error);
            });

        axios.get('/Process/InsInstitucional/institucional/18/informacoes')
            .then(response => {
                this.informacoes = response.data.filter(x => x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
            })
            .catch(error => {
                console.error(error);
            });

        await axios.get('/Process/ProSegmentos/GetAll')
            .then(response => {
                this.Segmentos = response.data;
                this.SegmentosMenu = this.Segmentos.filter(x => x.psgIdPai == 0 && x.psgVisivel).sort((a, b) => {
                    const nomeA = a.psgNomeSegmento || "";
                    const nomeB = b.psgNomeSegmento || "";
                    return nomeA.localeCompare(nomeB);
                });
            })
            .catch(error => {
                console.error(error);
            });

            await axios.get('/Process/InsSegmentos/GetAll')
            .then(response => {
                this.SegmentosInst = response.data;
                this.SegmentosMenuInst = this.SegmentosInst.filter(x => x.sgiIdPai == 0 && x.sgiVisivel).sort((a, b) => {
                    const nomeA = a.sgiNomeSegmento || "";
                    const nomeB = b.sgiNomeSegmento || "";
                    return nomeA.localeCompare(nomeB);
                });

                this.SegmentoVideo = this.SegmentosMenuInst.filter(x=> x.ctiId == 35).sort((a, b) => a.sgiOrdem - b.sgiOrdem);
                this.SegmentoCatalogo = this.SegmentosMenuInst.filter(x=> x.ctiId == 31).sort((a, b) => a.sgiOrdem - b.sgiOrdem);

            })
            .catch(error => {
                console.error(error);
            });
    },
    computed: {
        ...mapState(['variavelHeader1', 'variavelHeader2'])
    },
}
</script>

<style>
@media (max-width:768px) {
    .v-row {
    display: flex;
    flex-wrap: wrap;
    flex: 0 1 auto !important;
    margin: 0 !important;
}

    .logo-col {
        flex: 1;
        /* Ajuste o valor para controlar o tamanho do logo */
    }

    .icon-col {
        flex: 1;
        /* Ajuste o valor para controlar o tamanho dos ícones e o espaço entre eles */
    }

    .search-container {
        position: absolute;
        background-color: white;
        /* Cor de fundo do campo de pesquisa */
        z-index: 1;
        /* Para sobrepor o conteúdo abaixo */
        width: 100%;
        /* Para ocupar a largura da barra de aplicativos */
        top: 60px;
        /* Ajuste conforme necessário para a posição vertical desejada */
        padding: 10px;
        /* Espaçamento interno do campo de pesquisa */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        /* Sombra, opcional */
        margin-left: -15px !important;
    }

    .search-input {
        width: 100%;
        padding: 10px;
    }

    .cart-count {
    position: absolute;
    top: -3px;
    right: -3px;
    background-color: #B91F1C;;
    color: white;
    border-radius: 50%;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 2px;
    font-size: 9px;
}

@media (min-width:1200px) {
    a .btn .cart-count {
    position: absolute;
    top: -15px;
    right: 9px;
    background-color: #B91F1C;;
    color: white;
    border-radius: 50%;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 2px;
    font-size: 9px;
}
}

}
</style>
