<template>
  <div>
    <section style="background-color:#0a98f8;height:20px;">
      </section>
    <footer>
      <!-- Footer Start-->
      <div class="footer-main" v-if="dados != ''">
        <div class="footer-area footer-padding">
          <div class="container">
            <div class="row  justify-content-between">
              <div class="col-lg-4 col-md-4 col-sm-8">
                <div class="single-footer-caption mb-30">
                  <!-- logo -->
                  <div class="footer-logo">
                    <div>
                      <router-link to="/" class="big-logo">
                        <img src="/imagens/logorodape.png" alt="" style="width: 210px; height: auto;">
                      </router-link>
                    </div>
                  </div>
                  <div class="footer-tittle">
                    <div class="footer-pera">
                      <p class="info1" v-html="dados[0].itiResumo1"></p>
                    </div>
                  </div>
                  <div class="header-info-right" v-if="redes!=''"  style="padding-top: 30px;">
                        <ul class="header-social" style="display: inline-flex;">
                            <li style="padding: 5px;"><a v-if="redes[0].itiReferencia != '' && redes[0].itiReferencia != null"
                                    :href="redes[0].itiReferencia" target="_blank"><i
                                        class="fab fa-instagram"></i></a></li>
                            <li style="padding: 5px;"><a v-if="redes[0].itiNome != '' && redes[0].itiNome != null"
                                    :href="redes[0].itiNome" target="_blank"><i
                                        class="fab fa-facebook-f"></i></a></li>
                            <li style="padding: 5px;"><a v-if="redes[0].itiCampoExtra2 != '' && redes[0].itiCampoExtra2 != null"
                                    :href="redes[0].itiCampoExtra2" target="_blank"><i
                                        class="fab fa-linkedin-in"></i></a></li>
                            <li style="padding: 5px;"> <a v-if="redes[0].itiCampoExtra1 != '' && redes[0].itiCampoExtra1 != null"
                                    :href="redes[0].itiCampoExtra1" target="_blank"><i
                                        class="fab fa-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-4 col-sm-5">
                <div class="single-footer-caption mb-50">
                  <div class="footer-tittle">
                    <h4>Menu</h4>
                    <ul class="widget-list">
                      <li><router-link to="/">Home</router-link></li>
                      <li><router-link to="/sobre">Sobre</router-link></li>
                      <li><router-link to="/produtos/0/Marcas">Produtos</router-link></li>
                      <li><router-link to="/blog">Blog</router-link></li>
                      <li><router-link to="/TreinamentosEventos">Treinamentos e Eventos</router-link></li>
                      <li><router-link to="/Catalogos">Catálogos</router-link></li>
                      <li><router-link to="/faleconosco">Fale Conosco</router-link></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-7">
                <div class="single-footer-caption mb-50">
                  <div class="footer-tittle">
                    <h4>Meus dados</h4>
                    <ul class="widget-list">
                      <li><router-link to="/login">Login</router-link></li>
                      <li><router-link to="/carrinho">Carrinho</router-link></li>
                      <li><router-link to="/meuspedidos">Meus Pedidos</router-link></li>
                      <li><router-link to="/meusdados">Minha Conta</router-link></li>
                    </ul><!-- End .widget-list -->

                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-8">
                <div class="footer-tittle">
                  <h4>Informações Gerais</h4>
                  <ul class="widget-list">
                      <li v-for="itens in informacoes" :key="itens.itiId">
                          <router-link :to="'/informacoes/' + itens.itiId + '/' + itens.itiNome.replaceAll(' ','-').replaceAll('/','').replaceAll(',','')">
                            {{itens.itiNome}}
                        </router-link></li>
                  </ul><!-- End .widget-list -->
                </div>
              </div>
            </div>
            <!-- Copy-Right -->
            <div style="padding-top: 60px;">
              <p style="color:#ffffff">&copy; {{ currentYear }} Corofergs Soluções Industriais. Todos os direitos reservados.</p>
            </div>
            <div class="row align-items-center">
              <div class="col-xl-12 ">
                <div class="footer-copy-right">
                  <a href="https://avancedigital.com.br/" target="_blank" style="font-size: 12px;">
                        Desenvolvido por <img src="/images/avanceBranco.png" alt="" style="height: 15px;">
                      </a>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
      
      <!-- Footer End-->
    </footer>
    <div v-if="dados != ''" class="whatsapp-fixo">
      <a :href="dados[0].itiCampoExtra1" target="_blank">
        <img src="/images/atendimentowats.png" alt="Fale Conosco pelo WhatsApp" />
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
export default {
  name: 'PageFooter',
  components: {

  },
  data: () => ({
    dados: [],
    redes: [],
    informacoes: [],
    callaction: [],
    LinkWhats: [],
    currentYear: moment().year(),
  }),
  methods: {

  },
  created() {
    axios.get('/Process/InsInstitucional/institucional/10/rodape')
      .then(response => {
        this.dados = response.data;
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('/Process/InsInstitucional/institucional/20/redessociais')
      .then(response => {
        this.redes = response.data;
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('/Process/InsInstitucional/institucional/18/informacoes')
      .then(response => {
        this.informacoes = response.data.filter(x => x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
      })
      .catch(error => {
        console.error(error);
      });
  }
}
</script>

<style scoped>
.menuTamanho {
  font-size: 20px;
  text-align: center;
}

/* WHATSAPP FIXO */
.whatsapp-fixo {
  position: fixed;
  bottom: 20px;
  left: 10px;
  z-index: 999;
}
</style>
